.calc-material-area {
  padding: 20px;

  .result-title-area {
    display: inline-flex;
    flex-wrap: nowrap;
    align-items: center;

    .result-title {
      margin-top: 15px;
      margin-right: 20px;
    }

    Button {
      box-shadow: 0px 15px 15px rgba(0, 0, 0, 0.1);
      // border-radius: 10px;
    }
  }

  .data-grid {
    tr > td {
      &:nth-child(1),
      &:nth-child(2),
      &:nth-child(3),
      &:nth-child(6) {
        text-align: left;
      }
    }
  }
}
